import React from "react";

import Header from "../components/header";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <>
      <SEO title="404: Página não encontrada" />
      <Header />
      <div className="h-screen flex justify-center items-center bg-verde">
        <span className="text-2xl font-medium inline-block text-white">
          Aparentemente você chegou a um lugar que não existe...
        </span>
      </div>
    </>
  );
}

export default NotFoundPage;
